.homeH1 {
    font-family: 'Unbounded';
    color: antiquewhite;
}

.home {
    font-family: 'Unbounded';
}

.checkbox {
    background: #242423;
    border: none;
    cursor: pointer;
}

.homeInput {
    width: 200px;
    height: 40px;
    background-color: #242423;
    border-radius: 8px;
    border: 2px solid antiquewhite;
    padding: 0 10px 0 10px;
    font-family: 'Unbounded';
    color: antiquewhite;
}

.selectNbPlayers {
    width: 220px;
    height: 44px;
    background-color: #242423;
    border-radius: 8px;
    border: 2px solid antiquewhite;
    padding: 0 10px 0 10px;
    font-family: 'Unbounded';
    color: antiquewhite;
    cursor: pointer;
}

.home .name {
    width: 500px;
    height: 50px;
    border: 4px solid antiquewhite;
    margin-bottom: 50px;
    text-align: center;
}

.nameContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
}

.nameContainer p {
    font-family: 'Unbounded';
}

.home button {
    width: 220px;
    height: 40px;
    background-color: antiquewhite;
    border-radius: 8px;
    border: none;
    padding: 0 10px 0 10px;
    font-family: 'Unbounded';
    transition-duration: 0.3s;
    cursor: pointer;
}

.home button:hover {
    transform: scale(1.10);
}

.home button:active {
    transform: scale(0.95);
}

.forms {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formJoinGame {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    gap: 10px;
    height: 124px;
}

.formCreateGame {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.publicGamesList {
    margin-left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Unbounded';
}

.publicGamesList button {
    width: 100px;
    font-size: 12px;
}

.gamesList {
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 2px solid antiquewhite;
    border-radius: 17px;
    height: 300px;
    width: 450px;
    display: flex;
    justify-content: baseline;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    /* Ajoute un défilement vertical */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    scrollbar-width: thin;          /* "auto" or "thin" */
    scrollbar-color: antiquewhite transparent;
}


.gamesList button {
    margin-left: 15px;
}

.barreDroite {
    margin-top: 30px;
    margin-left: 20px;
    background-color: antiquewhite;
    height: 400px;
    width: 2px;
}

@media (max-width: 1024px) { /* iPhone */
    .home .name {
        width: 250px;
        height: 30px;
        font-size: 12px;
    }

    .forms{
        flex-direction: column;
        font-size: 12px;
        gap: 20px;
    }

    .barreDroite{
        display: none;
    }

    .formJoinGame{
        justify-content: flex-start;
        gap: 10px;
    }

    .publicGamesList {
        margin-left: 0px;
    }

    .gamesList {
        width: 300px;
        margin-bottom: 50px;
    }

    .lobbyGamesList{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 20px 0px 20px;
    }
}

@media (max-width: 335px) { /*  Fold 3 fermé */
    .publicGamesList h2{
        font-size: 16px;
    }
}