.cartes-adversaire-container {
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative; /* Le conteneur doit être positionné */
}


.cartes-adversaire {
    display: flex;
    justify-content: center;
    width: 80%;
    height: 21%;
}

.trios-adversaire-container{
    width: auto;
    height: 10%;
    display: flex;
    justify-content: left;
    align-items: center;
    transform: translateY(600%) ;
}

.cartes-adversaire-container button {
    width: 80px;
    height: 5%;
    margin-bottom: 3%;
    margin-right: 1%; 
    margin-left: 1%;
    border-radius: 13px;
    background-color: antiquewhite;
    color: black;
    cursor: pointer;
    transition-duration: 0.2s;
    font-size: 22px;
    border: none;
}


.largest:hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.37); 
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.37);
}

.smallest:hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.37); 
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.37);
}

.name {
    font-family: 'Unbounded';
    font-size: 24px;
}

.hideBtn {
    visibility: hidden;
    display: none;
}

.cartes-adversaire-et-trios{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20%;
    width: 100%;
}

.nocards .trios-container{
    transform: translateY(0px) translateX(10px);
}

.mobile{
    display: none;
}

.linksTrios.smaller{
    transform: scale(0.7) translateY(50px) translateX(10px);
    justify-content: center;
}

.linksTrios.hidden{
    display: none;
}

@media (max-width: 1024px) {

    /* iPhone */


    .cartes-adversaire-container button {
        display: none;
    }


    .nameAndTriosContainer p{
        font-size: 15px;
    }

    .mobile{
        display: block;
    }

    .nbCartes{
        transform: translateY(60px);
        font-family: 'Unbounded';
        font-size: 12px;
    }

    .trios-adversaire-container{
        transform: translateY(100%) scale(0.7);
    }
}

