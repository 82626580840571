.carte-adversaire {
    width: 100%; 
    height: 800%;
    perspective: 1000px;
    transition: transform 0.6s ease;
}

.carte-adversaire.flipped .cardAdv {
    transform: rotateY(180deg);
}

.cardAdv {
    position: relative;
    transform-style: preserve-3d;
    /* Active la 3D */
    transition: transform 0.6s, box-shadow 0.3s;
    /* Durée et fluidité de l'animation */

    width: 70px;
    height: 100%;
    background-color: rgb(52, 52, 52);
    border-radius: 17px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    color: antiquewhite;

    font-family: "Goblin One", serif;
    font-weight: 50;

    margin-left: 5%;
    margin-right: 5%;
}

.cardAdv-front,
.cardAdv-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cardAdv-front {
    color: black;
    border: 5px solid black;
    font-size: 32px;
}
.cardAdv-front h1{
    font-size: 32px;
}

.cardAdv-back {
    background-color: rgb(37, 33, 33);
    /* Face dos */
    color: antiquewhite;
    border: 5px solid antiquewhite;
    font-size: 7px;
    transform: rotateY(180deg);
}


.carte-adversaire.isTrio .cardAdv {
    animation: growAndMove 2s ease-in-out; 
    z-index: 40;
}

@keyframes growAndMove {
    0% {
        transform: scale(1);
    }
    20%{
        transform: scale(1) translateY(50px);
    }
    40% {
        transform: scale(1) translateY(-1500px);
    }
    100% {
        transform: scale(1) translateY(-1500px);
    }
}

.underlined {
    text-decoration: underline;
}

.mobile{
    display: none;
}

.isLargest, .isSmallest{
    cursor: pointer;
}

@media (max-width: 1024px) {

    /* iPhone */
    .pc{
        display: none;
    }
    .mobile{
        display: flex;
    }

    .cardAdv {
        position: relative;
        width: 40px;
        height: 70px;
        
        font-weight: 50;

    
        margin-left: 5%;
        margin-right: 5%;
    }

    .cardAdv-back {
        font-size: 5px;
        border: 3px solid antiquewhite;
    }

    .cardAdv-front{
        border: 3px solid black;
        font-size: 16px;
    }

    .fontLarger{
        font-size: 20px;
    }
    
    .middleCard{
        display: none;
    }

    .middleCard.visible {
        display: block;
    }
}