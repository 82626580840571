.lobby {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Unbounded';
    height: 80vh;
}

.listPlayer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

@media (max-width: 1024px) {

    /* iPhone */

    .lobby {
        justify-content: flex-start;
        margin-top: 30px;
    }
    
    .lobby h1 {
        font-size: 24px;
        
    }

}