.CartesContainer {
    width: 80%;
    height: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 50%;
    bottom: -130px;
    transform: translateX(-50%) scale(0.9);

    z-index: 200;
}

.noCards {
    font-family: 'Unbounded';
    font-size: 24px;
    margin-bottom: 6%;
}

.showColors {
    position: fixed;
    left: 50px;
    bottom: 50px;
    width: 90px;
    height: 35px;
    background-color: antiquewhite;
    border: none;
    border-radius: 17px;
    cursor: pointer;
    transition-duration: 0.3s;
    font-family: 'Unbounded';
    color: black;
}

.showColors:hover {
    transform: scale(1.05);
}

.showColors:active {
    transform: scale(0.97);
}

.showColors.coloredBtn {
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(236, 192, 22, 1) 51%, rgba(0, 212, 255, 1) 100%);
    color: white;
}

.selectSkin {
    position: fixed;
    left: 50px;
    bottom: 100px;
    width: 90px;
    height: 35px;
    background-color: antiquewhite;
    border: none;
    border-radius: 17px;
    cursor: pointer;
    transition-duration: 0.3s;
    font-family: 'Unbounded';
    color: black;
}

.selectSkin:hover {
    transform: scale(1.05);
}

.selectSkin:active {
    transform: scale(0.97);
}

.selectSkin.coloredBtn {
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(255, 142, 0, 1) 50%, rgba(255, 194, 0, 1) 100%);
    color: white;
}

@media (max-width: 1024px) {

    /* iPhone */

    .CartesContainer {
        width: 80%;
        height: 300px;

        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
    }

    .showColors {
        display: none;
    }

    .selectSkin {
        display: none;
    }

}