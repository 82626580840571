.trios-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    transform: translateY(100px) translateX(10px);
}

.trio-icon {
    width: 50px;
    height: 60px;
    position: relative;
}

.trio-stack {
    position: relative;
    width: 100%;
    height: 100%;
}

.trio-top,
.trio-middle,
.trio-bottom {
    position: absolute;
    width: 57%;
    height: 70%;
    border: 2px solid black;
    border-radius: 7px;
    text-align: center;
    line-height: 20px;
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.10); 
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.10);
}

.trio-top {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-family: 'Goblin One';
    color: black;
}

.trio-top img,
.trio-middle img,
.trio-bottom img{
    width: 100%;
    height: 100%;
    position: absolute;
}

.bigger {
    width: 100%;
    height: 120%;
    font-size: 30px;
    border: 4px solid black;
}

.trio-middle {
    transform: rotate(30deg) ;
}

.trio-bottom {
    transform: rotate(70deg);
}
