@import url('https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200..800&family=Goblin+One&family=Leckerli+One&family=Lexend+Deca:wght@100..900&family=Pacifico&family=Parkinsans:wght@300..800&family=Rye&family=Unbounded:wght@200..900&family=Vina+Sans&display=swap');


.Default {
    font-family: 'Lexend Deca';
}
.Default p{
    font-weight: bold;
}

.DefaultLetters {
    font-family: 'Lexend Deca';
}
.DefaultLetters p{
    font-weight: bold;
}

.Fat, .Skin2, .Skin4, .Skin7{
    font-family: "Goblin One", serif;
}

.Skin1{ /* Dead Blossom */
    color: black;
    font-family: 'Leckerli One';
}

.Skin1 p{ /* Dead Blossom */
    top: -25px;
    right: 25px;
}

.Carte.Skin3 { /* Strings */
    font-family: 'Pacifico';
    color: white;
    border: 5px solid white;
}

.Skin3.card-front { 
    font-family: 'Pacifico';
    color: white;
    border: 5px solid white;
    font-size: 45px;
}

.Skin3.cardAdv-front { 
    font-family: 'Pacifico';
    color: white;
    border: 5px solid white;
}

.Carte.Skin3 p{ 
    top: -35px;
    right: 25px;
}
.Skin3 h1{ 
    transform: translateY(-10px);
}

.Skin5{
    font-family: 'Parkinsans';
}
.Skin5 p{
    font-size: 32px;
    font-weight: bold;
    top: -25px;
}

.Skin6{
    font-family: 'Leckerli One';
}
