.selectSkinContainer{
    background-color: #1a1a1a;
    width: 900px;
    height: 550px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 100;
    border-radius: 21px;
    padding: 50px 70px;
}

.skinName{
    font-family: 'Unbounded';
    font-size: 15px;
    text-align: center;
}

.selectSkinContainer img{
    width: 90px;
    height: 130px;
}

.selectSkinContainer .Carte{
    height: 190px;
    width: 125px;
    cursor: pointer;
    transition-duration: 0.3s;
}

.selectSkinContainer .Carte:hover{
    transform: scale(1.03);
}

.selectSkinContainer .Carte:active{
    transform: scale(0.98);
}

.selectSkinContainer .Carte p{
    top: -13px;
    right: 15px;
    font-size: 23px;
}

.selectSkinContainer .Carte h1{
    font-size: 60px;
}

.selectSkinContainer .Carte img{
    height: 100%;
    width: 100%;
}

.skins{
    display: flex;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}
.home .Close, .Close {
    position: absolute;
    right: 30px;
    top: 30px;
    height: 40px;
    width: 40px;
    background-color: antiquewhite;
    border-radius: 8px;
    border: none;
    padding: 0 10px 0 10px;
    font-family: 'Unbounded';
    transition-duration: 0.3s;
    cursor: pointer;
}

.Close:hover {
    transform: scale(1.10);
}

.Carte.selectedSkin{
    transform: scale(1.06);
}