.menuCardsContainer {
    width: 100%;
    height: 10px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
}

.menuCard {
    width: 160px;
    height: 250px;
    transform: translateY(-130px);
    color: antiquewhite;

    border: 7px solid black;

    font-family: "Unbounded", serif;

    border-radius: 17px;

    display: flex;
    justify-content: center;
    align-items: top;
    font-size: 26px;
    padding-top: 50px;

    transition-duration: 0.3s;
    cursor: pointer;
}

.menuCard h1 {
    font-size: 26px;
}

.menuCard img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.menuCardSkins {
    background: rgb(2, 0, 36);
    background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(255, 142, 0, 1) 50%, rgba(255, 194, 0, 1) 100%);
}

.menuCardAbout h1{
    font-size: 23px;
}

.centerMenuCard:hover {
    transform: translateY(-170px);
}

.centerMenuCard{
    z-index: 9;
}

.left1MenuCard{
    transform: translateY(-120px) translateX(30px) rotate(-10deg);
    z-index: 10;
}
.left1MenuCard:hover, .left1MenuCard.selected{
    transform: translateY(-160px) translateX(10px) rotate(-10deg);
}

.right1MenuCard{
    transform: translateY(-120px) translateX(-30px) rotate(10deg);
    z-index: 8;
}
.right1MenuCard:hover{
    transform: translateY(-160px) translateX(-10px) rotate(10deg);
}