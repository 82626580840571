.card-container {
    perspective: 1000px;
    /* Crée une perspective 3D pour l'animation */
    width: 150px;
    height: 200px;
}

.card {
    position: relative;
    transform-style: preserve-3d;
    /* Active la 3D */
    transition: transform 0.6s, box-shadow 0.3s;
    /* Durée et fluidité de l'animation */

    width: 100px;
    height: 150px;
    background-color: rgb(52, 52, 52);
    border-radius: 17px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    color: antiquewhite;

    font-family: "Goblin One", serif;
    font-weight: 50;

    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.skinCard{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.card-container.flipped .card {
    transform: rotateY(180deg);
    /* Retourne la carte */
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    /* Masque la face arrière lors de la rotation */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-front {
    color: black;
    transform: rotateY(180deg);
    /* Initialement masquée */
    border: 5px solid black;
    font-size: 32px;
}

.card-front h1{
    font-size: 38px;
}

.card-back {
    background-color: rgb(37, 33, 33);
    /* Face dos */
    color: antiquewhite;
    border: 5px solid antiquewhite;
    font-size: 12px;
}

.card:hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 9px 15px 5px rgba(0, 0, 0, 0.65);
    box-shadow: 0px 9px 15px 5px rgba(0, 0, 0, 0.65);
}

.card:active {
    transform: scale(1);
}

.card-container.isTrio .card{
    animation: growAndMoveCenter 2s ease-in-out; 
    z-index: 40;
    transform:rotateY(180deg);
} 

.isDeleted{
    visibility: hidden;
}

@keyframes growAndMoveCenter {
    0% {
        transform: scale(1) rotateY(180deg) translateY(0); /* Carte initialement face visible */
    }
    20% {
        transform: scale(1) rotateY(180deg) translateY(-50px); /* Mi-chemin */
    }
    40% {
        transform: scale(1) rotateY(180deg) translateY(1500px); /* Face cachée au centre */
    }
    100% {
        transform: scale(1) rotateY(180deg) translateY(1500px); /* Face cachée au centre */
    }
}


@media (max-width: 1024px) {

    /* iPhone */
    
    .card-container {
        perspective: 1000px;
        /* Crée une perspective 3D pour l'animation */
        width: 50px;
        height: 75px;
    }
    
    .card {
        position: relative;
        transform-style: preserve-3d;
        /* Active la 3D */
        transition: transform 0.6s, box-shadow 0.3s;
        /* Durée et fluidité de l'animation */
    
        width: 40px;
        height: 65px;
        background-color: rgb(52, 52, 52);
        border-radius: 17px;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        position: relative;
    
        color: antiquewhite;
    
        font-family: "Goblin One", serif;
        font-weight: 30;
    
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
    }

    .card-front {
        color: black;
        transform: rotateY(180deg);
        /* Initialement masquée */
        border: 3px solid black;
        font-size: 20px;
    }
    
    .card-back {
        background-color: rgb(37, 33, 33);
        /* Face dos */
        color: antiquewhite;
        border: 3px solid antiquewhite;
        font-size: 5px;
    }
}