.cartesCentralesContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    transform: scale(0.8);
}

@media (max-width: 1024px) {

    /* iPhone */
    .cartesCentralesContainer {
        width: 80%;
    }
}