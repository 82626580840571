@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Parkinsans:wght@300..800&family=Rye&family=Unbounded:wght@200..900&display=swap');

.headerContainer h1{
    font-family: 'Unbounded';
    color: antiquewhite;
    letter-spacing: 5px;
    transition-duration: 0.2s;
}

.headerContainer h1:hover{
    cursor: pointer;
    letter-spacing: 15px;
}

.headerContainer button{
    width: 300px;
    height: 40px;
    border: none;
    color: antiquewhite;
    background-color: transparent;
    font-family: 'Unbounded';
    transition-duration: 0.2s;
}

.headerContainer button:hover {
    cursor: pointer;
    letter-spacing: 2px;
}

.headerContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

@media (max-width: 1024px) {

    /* iPhone */
    .headerContainer{
        height: 40px;
    }

    .headerContainer h1{
        font-size: 22px;
    }


}