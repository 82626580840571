.game-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    /* Prend tout l'écran */
}

.adversaire {
    padding: 10px;
    height: 100%;
    width: 500px;
    min-width: 350px;
}

.adversairesContainer {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.9);
}

.cartes-centrales {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 100;
}

.main-joueur {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.tourDesc {
    font-family: 'Unbounded';
    text-align: center;
}

.trios-joueur {
    width: 150px;
    height: 300px;
    position: fixed;
    bottom: -5%;
    right: 6%;
    transform: scale(0.7);
    background-color: rgb(77, 77, 77);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    border-radius: 21px 21px 0 0;

    z-index: 4;
}

.CartesContainer {
    z-index: 5;
}

.main-joueur .isUpper {
    transform: translateY(-5%) translateX(-50%) scale(0.9);
}

.positionFirst {
    transform: translateY(40%) rotate(-25deg);
}

.positionLast {
    transform: translateY(40%) rotate(25deg);
}

.positionFirst.is5players {
    transform: translateY(40%) rotate(-25deg) translateX(-50px) scale(0.9);
}

.positionLast.is5players {
    transform: translateY(40%) rotate(25deg) translateX(50px);
}

.is5players {
    transform: scale(0.9);
}


.scaleFourPlayersAdv.positionLast {
    transform: translateY(50%) rotate(30deg);
}

.positionFirst.isUpper4players {
    transform: translateY(10%) rotate(-20deg) translateX(-100px);
}

.positionLast.isUpper4players {
    transform: translateY(10%) rotate(20deg) translateX(100px);
}

.positionDecaleGauche {
    transform: translateX(-20%);
}

.positionDecaleDroit {
    transform: translateX(20%);
}

.trioStackAndLinks {
    display: flex;
    justify-content: flex-end;
}

.hidden {
    display: none;
}

.linksTrios {
    position: absolute;
    transform: translateX(150px);
    display: flex;
    width: 80px;
    justify-content: left;
}

.linksMiniCard {
    display: flex;
    height: 50px;
    width: 30px;
    border-radius: 5px;
    border: solid 4px black;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: 'Goblin One';
    transform: translateY(10px);
}

.scaleSevenPlayersAdv {
    transform: scale(0.73);
}

.scaleSevenPlayersCentre {
    transform: scale(0.9);
}

.scaleSixPlayersAdv {
    transform: scale(0.87);
}

.WinningTrioContainer {
    position: absolute;
    top: 20px;
    left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 120px;
    font-family: 'Unbounded';
    font-size: 11px;
}

.winning-trio-animation {
    position: absolute;
    top: -45px;
    left: -20px;
    z-index: 250;
    transform: scale(0.3);
    animation: fadeInOut 2s linear;
}

.reconnexion {
    font-family: 'Unbounded';
    text-align: center;
}

@keyframes fadeInOut {
    0% {
        transform: scale(1) translateY(-50%);
        top: 50%;
        left: 150%;
    }

    15% {
        transform: rotate(5deg) scale(1) translateY(-50%) translateX(-50%);
        left: 50%;
    }

    18% {
        transform: rotate(-5deg) scale(1) translateY(-50%) translateX(-50%);
        left: 47%;
    }

    25% {
        transform: rotate(5deg) scale(1) translateY(-50%) translateX(-50%);
        left: 50%;
    }

    30% {
        transform: rotate(-0deg) scale(1) translateY(-50%) translateX(-50%);
        left: 50%;
    }

    80% {
        transform: scale(1) translateY(-50%) translateX(-50%);
        top: 50%;
        left: 50%;
    }

    99% {
        transform: scale(0.3) translateY(0);
        top: -45px;
        left: -20px;
    }
}

.reconnexion-text {
    font-size: 32px;
    font-family: 'Unbounded';
    text-align: center;
    margin-top: 320px;
}

.reconnexion-text span {
    opacity: 0;
    animation: blink 1.5s infinite;
}

.reconnexion-text span:nth-child(1) {
    animation-delay: 0.3s;
}

.reconnexion-text span:nth-child(2) {
    animation-delay: 0.6s;
}

.reconnexion-text span:nth-child(3) {
    animation-delay: 0.9s;
}

@keyframes blink {

    0%,
    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@media (max-width: 1024px) {

    /* iPhone */

    .adversairesContainer {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .adversaire {
        height: 140px;
        width: 100px;
    }


    .positionFirst {
        transform: translateY(0px) rotate(0deg);
    }

    .positionLast {
        transform: translateY(0px) rotate(0deg);
    }

    .positionFirst.isUpper4players {
        transform: translateY(0%) rotate(0deg);
    }

    .positionLast.isUpper4players {
        transform: translateY(0%) rotate(0deg);
    }


    .positionDecaleGauche {
        transform: translateX(0%);
    }

    .positionDecaleDroit {
        transform: translateX(0%);
    }

    .tourDesc {
        font-size: 12px;
        transform: translateY(10px);
    }

    .main-joueur .trios-joueur {
        left: -40px;
        bottom: 0px;
        transform: rotate(90deg);
        width: 80px;
        justify-content: flex-start;
        gap: 0px;
        background-color: transparent;
    }

    .main-joueur .trio-icon {
        transform: scale(0.5) rotate(-90deg);
    }

    .adversairesContainer.isUpper {
        transform: translateY(-70px);
    }

    .cartes-centrales.isUpper {
        transform: translateY(-100px);
    }

    .tourDesc.isUpper {
        transform: translateY(-100px);
    }

}