.bg-1 {
    background-color: rgb(128, 41, 128);
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(181, 0, 255, 1) 0%, rgba(147, 0, 255, 1) 100%);
}

.bg-2 {
    background-color: rgb(222, 178, 95);
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(255, 148, 88, 1) 0%, rgba(255, 176, 69, 1) 100%);
}

.bg-3 {
    background-color: rgb(105, 105, 247);
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(88, 113, 255, 1) 0%, rgba(82, 69, 255, 1) 100%);
}

.bg-4 {
    background-color: rgb(249, 111, 111);
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(255, 88, 88, 1) 0%, rgba(255, 69, 93, 1) 100%);
}

.bg-5 {
    background-color: rgb(255, 147, 230);
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(247, 148, 255, 1) 0%, rgba(237, 6, 255, 1) 100%);
}

.bg-6 {
    background-color: rgb(153, 227, 153);

    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(17, 223, 38, 1) 0%, rgba(11, 171, 25, 1) 100%);
}

.bg-7 {
    background-color: rgb(255, 204, 0);
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(255, 245, 78, 1) 0%, rgba(255, 213, 15, 1) 100%);
}

.bg-8 {
    background-color: rgb(66, 134, 66);
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(0, 224, 32, 1) 0%, rgba(0, 150, 8, 1) 100%);
}

.bg-9 {
    background-color: rgb(196, 106, 106);
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(255, 21, 111, 1) 0%, rgba(237, 0, 75, 1) 100%);
}

.bg-10 {
    background-color: rgb(243, 224, 80);
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(255, 210, 17, 1) 0%, rgba(238, 177, 0, 1) 100%);
}

.bg-11 {
    background-color: rgb(134, 165, 87);
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(143, 230, 96, 1) 0%, rgba(84, 195, 62, 1) 100%);
}

.bg-12 {
    background-color: rgb(117, 212, 228);
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(255, 0, 166, 1) 0%, rgba(9, 249, 227, 1) 0%, rgba(11, 209, 191, 1) 100%);
}

.bg-13 {
    background-color: rgb(192, 90, 255);
}

.bg-default {
    background-color: gray;
}