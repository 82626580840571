.Carte{
    width: 160px;
    height: 250px;
    background-color: rgb(31, 31, 31);
    border-radius: 17px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    position: relative;

    color: black;

    border: 5px solid black;

    font-weight: 50;
}

.Carte img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.Carte h1{
    font-size: 80px;
}

.Carte p{
    font-size: 32px;
    position: absolute;
    top: -20px;
    right: 20px;
}

.CarteHover{
    transition-duration: 0.3s;
    cursor: pointer;
}

.CarteHover.cantBePlayed:hover{
    filter: grayscale(100%);
    cursor: default;
}


.CarteHover:after{
    transform: translateY(-50%) ;
}

.slotGauche1{
    transform: rotate(-5deg) translateX(30px) ;
    z-index: 18;
}
.slotGauche2{
    transform: rotate(-10deg) translateX(60px) translateY(30px);
    z-index:19;
}
.slotGauche3{
    transform: rotate(-15deg) translateX(90px) translateY(80px);
    z-index: 20;
}
.slotGauche4{
    transform: rotate(-20deg) translateX(120px) translateY(150px);
    z-index: 21;
}

.slotCentre{
    z-index: 17;
    transform: translateY(-10px) ;
}

.slotDroite1{
    transform: rotate(5deg) translateX(-30px);
    z-index: 16;
}
.slotDroite2{
    transform: rotate(10deg) translateX(-60px) translateY(30px) ;
    z-index: 15;
}
.slotDroite3{
    transform: rotate(15deg) translateX(-90px) translateY(80px) ;
    z-index: 14;
}
.slotDroite4{
    transform: rotate(20deg) translateX(-120px) translateY(150px);
    z-index: 13;
}

.slotGauche1pairAmount{
    transform: rotate(-5deg) translateX(30px) ;
    z-index: 18;
}
.slotGauche2pairAmount{
    transform: rotate(-10deg) translateX(60px) translateY(30px);
    z-index:19;
}
.slotGauche3pairAmount{
    transform: rotate(-15deg) translateX(90px) translateY(80px);
    z-index: 20;
}
.slotGauche4pairAmount{
    transform: rotate(-20deg) translateX(120px) translateY(150px);
    z-index: 21;
}


.slotDroite1pairAmount{
    transform: rotate(5deg) translateX(-30px);
    z-index: 16;
}
.slotDroite2pairAmount{
    transform: rotate(10deg) translateX(-60px) translateY(30px) ;
    z-index: 15;
}
.slotDroite3pairAmount{
    transform: rotate(15deg) translateX(-90px) translateY(80px) ;
    z-index: 14;
}
.slotDroite4pairAmount{
    transform: rotate(20deg) translateX(-120px) translateY(150px);
    z-index: 13;
}

.slotGauche4Hover:hover{
    transform: translateX(100px) translateY(-40%) ;
}

.slotDroite4Hover:hover{
    transform: translateX(-100px) translateY(-40%) ;
}

.slotGauche3Hover:hover{
    transform: translateX(100px) translateY(-40%) ;
}

.slotDroite3Hover:hover{
    transform: translateX(-100px) translateY(-40%) ;
}

.slotGauche2Hover:hover{
    transform: translateX(50px) translateY(-40%) ;
}

.slotDroite2Hover:hover{
    transform: translateX(-50px) translateY(-40%) ;
}

.slotGauche1Hover:hover{
    transform: translateY(-40%) ;
}

.slotDroite1Hover:hover{
    transform: translateY(-40%) ;
}

.slotCentreHover:hover{
    transform: translateY(-40%) ;
}


.slotGauche1Cancel{
    transform: 0;
    z-index: 18;
}
.slotGauche2Cancel{
    transform: 0;
    z-index:19;
}
.slotGauche3Cancel{
    transform: 0;
    z-index: 20;
}
.slotDroite1Cancel{
    transform: 0;
    z-index: 17;
}
.slotDroite2Cancel{
    transform: 0;
    z-index: 16;
}
.slotDroite3Cancel{
    transform: 0;
    z-index: 15;
}

.isRevealed{
    transform: translateY(-200px);
    filter: grayscale(50%); /* Effet grisé */
}

.isRevealed .Carte{
    color: antiquewhite;
    border: 5px solid antiquewhite;
}

.CarteHover.isTrio {
    filter: grayscale(50%);
    animation: growAndMoveHand 2s ease-in-out; 
    z-index: 40;
}


.CarteHover.isTrio:hover {
    filter: grayscale(50%);
    animation: growAndMoveHand 2s ease-in-out; 
}


@keyframes growAndMoveHand {
0% {
    transform: translateY(-200px) scale(1);
}
20% {
    transform: translateY(-350px) scale(1);
}
40% {
    transform: scale(1) translateY(1500px);
}
100% {
    transform: scale(1) translateY(1500px);
}
}

.isTrio .Carte{
    color: antiquewhite;
    border: 5px solid antiquewhite;
}

.underlined {
    text-decoration: underline;
}

.cardLinks.hidden {
    display: none;
}

.cardLinks{
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    justify-content: right;
    align-items: flex-end;
    gap: 6px;
    padding-bottom: 25px;
    padding-right: 25px;
}

.cardLinks .linksMiniCard {
    position:relative;
    top: auto;
    bottom: 5px;
    right: -4px;
    font-size: 18px;
    border: solid 2px black;
    border-radius: 5px;
    width: 33px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.linksMiniCard.hidden{
    display: none;
}



@media (max-width: 1024px) {

    /* iPhone */

    .Carte{
        width: 70px;
        height: 100px;
        background-color: rgb(31, 31, 31);
        border-radius: 17px;
    
        display: flex;
        justify-content: center;
        align-items: center;
        
        position: relative;
    
        color: black;
    
        border: 5px solid black;
    
        font-family: "Goblin One", serif;
        font-weight: 50;
    }
    
    .Carte h1{
        font-size: 25px;
    }
    
    .Carte p{
        font-size: 13px;
        top: -6px;
        right: 10px;
        position: absolute;
    }

    
    .CarteHover{
        transition-duration: 0.3s;
        cursor: pointer;
    }
    
    
    .CarteHover:after{
        transform: translateY(-50%) ;
    }


    .slotGauche1{
        transform: rotate(-4deg) translateX(45px);
        z-index: 18;
    }
    .slotGauche2{
        transform: rotate(-6deg) translateX(85px) translateY(15px);
        z-index:19;
    }
    .slotGauche3{
        transform: rotate(-8deg) translateX(120px) translateY(35px);
        z-index: 20;
    }
    .slotGauche4{
        transform: rotate(-10deg) translateX(160px) translateY(60px);
        z-index: 21;
    }
    
    .slotCentre{
        z-index: 17;
        transform: translateY(-5px) ;
    }
    
    .slotDroite1{
        transform: rotate(4deg) translateX(-45px);
        z-index: 16;
    }
    .slotDroite2{
        transform: rotate(6deg) translateX(-85px) translateY(15px) ;
        z-index: 15;
    }
    .slotDroite3{
        transform: rotate(8deg) translateX(-120px) translateY(35px) ;
        z-index: 14;
    }
    .slotDroite4{
        transform: rotate(10deg) translateX(-160px) translateY(60px);
        z-index: 13;
    }



    .slotGauche1pairAmount{
        transform: rotate(-4deg) translateX(25px) translateY(5px);
        z-index: 18;
    }
    .slotGauche2pairAmount{
        transform: rotate(-6deg) translateX(65px) translateY(15px);
        z-index:19;
    }
    .slotGauche3pairAmount{
        transform: rotate(-8deg) translateX(100px) translateY(35px);
        z-index: 20;
    }
    .slotGauche4pairAmount{
        transform: rotate(-10deg) translateX(140px) translateY(60px);
        z-index: 21;
    }
    
    .slotDroite1pairAmount{
        transform: rotate(4deg) translateX(-25px) translateY(5px);
        z-index: 16;
    }
    .slotDroite2pairAmount{
        transform: rotate(6deg) translateX(-65px) translateY(15px) ;
        z-index: 15;
    }
    .slotDroite3pairAmount{
        transform: rotate(8deg) translateX(-100px) translateY(35px) ;
        z-index: 14;
    }
    .slotDroite4pairAmount{
        transform: rotate(10deg) translateX(-140px) translateY(60px);
        z-index: 13;
    }

    .slotGauche1Hover:hover{
        transform: rotate(-4deg) translateX(45px);
        z-index: 18;
    }
    .slotGauche2Hover:hover{
        transform: rotate(-6deg) translateX(85px) translateY(15px);
        z-index:19;
    }
    .slotGauche3Hover:hover{
        transform: rotate(-8deg) translateX(120px) translateY(35px);
        z-index: 20;
    }
    .slotGauche4Hover:hover{
        transform: rotate(-10deg) translateX(160px) translateY(60px);
        z-index: 21;
    }
    
    .slotCentreHover:hover{
        z-index: 17;
        transform: translateY(-5px) ;
    }
    
    .slotDroite1Hover:hover{
        transform: rotate(4deg) translateX(-45px);
        z-index: 16;
    }
    .slotDroite2Hover:hover{
        transform: rotate(6deg) translateX(-85px) translateY(15px) ;
        z-index: 15;
    }
    .slotDroite3Hover:hover{
        transform: rotate(8deg) translateX(-120px) translateY(35px) ;
        z-index: 14;
    }
    .slotDroite4Hover:hover{
        transform: rotate(10deg) translateX(-160px) translateY(60px);
        z-index: 13;
    }
}