.chat-container {
    width: 200px;
    height: 270px;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 10px;
    bottom: 150px;
    z-index: 200;
    border: solid 2px antiquewhite;
    border-radius: 15px;
    padding: 15px;
    font-family: 'Unbounded';
    background-color: #242423;
    transition: ease-in-out 0.5s;
}

.chat-container.chatOppened {
    transform: translateX(0px);
}

.chat-container.chatClosed {
    transform: translateX(-245px);
}

.chat-container.chatClosed:hover {
    transform: translateX(-235px);
}


.chat-messages::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Edge */
}

.chat-messages {
    flex: 1;
    overflow-y: auto;

}

.chat-message {
    margin-bottom: 5px;
    font-size: 12px;
}

.chat-input {
    display: flex;
    padding: 10px;

}

.chat-input input {
    flex: 1;
    padding: 5px;
    border-radius: 4px;
    width: 10px;
    background-color: transparent;
    border: 2px solid antiquewhite;
    font-size: 12px;
    color: antiquewhite;
}

.chat-input button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    background: antiquewhite;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Unbounded';
    font-size: 12px;
}

.sendIcon {
    width: 16px;
    height: 16px;
}

.toggleChat {
    position: absolute;
    left: 100%;
    height: 40px;
    width: 40px;
    background-color: #242423;
    border: solid 2px antiquewhite;
    border-radius: 0px 10px 10px 0px;
    color: antiquewhite;
    cursor: pointer;
}

.notificationDot{
    width: 13px;
    height: 13px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    left: 113%;
    top: 13px;
    z-index: 210;
}